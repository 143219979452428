/* Add polyfills from the polyfills folder (order matters) */
import '@/polyfills/WeakMap';
import '@/polyfills/MutationObserver';
import '@/polyfills/ObjectFit';
import '@/polyfills/CustomProperties.min';

/* Add scrollbar-width variable for accurate calculations with calc */
document.documentElement.style.setProperty(
  '--scrollbar-width',
  window.innerWidth - document.documentElement.clientWidth + 'px',
);

/* Import Pristine Config (for HRM)  */
import '@config/pristine.config.js';

// /** Unicons: https://iconscout.com/unicons */
// import '@iconscout/unicons/css/unicons.css';

/* Alpine */
import 'alpinejs/dist/alpine-ie11';

/* Auto Imports */
const srcContext = require.context('@', true, /.(runtime|asset).(.*?)$/);
srcContext.keys().forEach(srcContext);

const templatesContext = require.context(
  '@root/templates',
  true,
  /.(runtime|asset|style).(.*?)$/,
);
templatesContext.keys().forEach(templatesContext);

const frontBundleContext = require.context(
  '@root/src/FrontBundle',
  true,
  /.(runtime|asset|style).(.*?)$/,
);
frontBundleContext.keys().forEach(templatesContext);

/* Import Styles */
import '@/styles/main.scss';
