import Cookies from 'js-cookie';

function cookieConsent() {
  const acceptedCookies = localStorage.getItem('acceptedCookies')
    ? JSON.parse(localStorage.getItem('acceptedCookies') as string)
    : {};
  return {
    show: false,
    acceptedCookies: {
      necessary: true,
      functional:
        typeof acceptedCookies.functional === 'boolean'
          ? acceptedCookies.functional
          : true,
      performance:
        typeof acceptedCookies.performance === 'boolean'
          ? acceptedCookies.performance
          : true,
      advertising:
        typeof acceptedCookies.advertising === 'boolean'
          ? acceptedCookies.advertising
          : true,
    },
    init() {
      if (Cookies.get('acceptedCookies')) {
        this.initScripts();
      } else {
        this.open();
      }
    },
    open() {
      this.show = true;
      document.body.style.overflow = 'hidden';
    },
    close() {
      this.show = false;
      document.body.style.overflow = 'auto';
    },
    accept() {
      this.initScripts();
      this.setCookie();
      this.close();
    },
    acceptAll() {
      Object.keys(this.acceptedCookies).forEach((key) => {
        (this as any).acceptedCookies[key] = true;
      });
      this.initScripts();
      this.setCookie();
      this.close();
    },
    continueWithoutAccepting() {
      this.setCookie(true);
      this.close();
    },
    setCookie(isSessionCookie: boolean = false) {
      const expiration = isSessionCookie
        ? null
        : { expires: 183 /* in 6 months */ };
      Cookies.set('acceptedCookies', true, expiration);
      if (!isSessionCookie) {
        Cookies.set(
          'cookies.necessary',
          this.acceptedCookies.necessary,
          expiration,
        );
        Cookies.set(
          'cookies.functional',
          this.acceptedCookies.functional,
          expiration,
        );
        Cookies.set(
          'cookies.performance',
          this.acceptedCookies.performance,
          expiration,
        );
        Cookies.set(
          'cookies.advertising',
          this.acceptedCookies.advertising,
          expiration,
        );
      }
      localStorage.setItem(
        'acceptedCookies',
        JSON.stringify(this.acceptedCookies),
      );
    },
    initScripts() {
      if (this.acceptedCookies.necessary) this.initNecessary();
      if (this.acceptedCookies.functional) this.initFunctional();
      if (this.acceptedCookies.performance) this.initPerformance();
      if (this.acceptedCookies.advertising) this.initAdvertising();
    },
    initNecessary() {
      const scripts = (window.CookieConsent || {}).necessary;
      this.handleScripts(scripts);
    },
    initFunctional() {
      const scripts = (window.CookieConsent || {}).functional;
      this.handleScripts(scripts);
    },
    initPerformance() {
      const scripts = (window.CookieConsent || {}).performance;
      this.handleScripts(scripts);
    },
    initAdvertising() {
      const scripts = (window.CookieConsent || {}).advertising;
      this.handleScripts(scripts);
    },
    handleScripts(scripts: any[] = []) {
      scripts.forEach((script: any) => {
        if (script.scripts) {
          script.scripts.forEach((childScript: any) => {
            const s: any = document.createElement('script');
            Object.keys(childScript).forEach((key) => {
              s[key] = childScript[key];
            });
            document.head.appendChild(s);
          });
        }
        if (script.callback) {
          script.callback();
        }
      });
    },
  };
}

(window as any).cookieConsent = cookieConsent;
