const screens = {
  // xs: '400px',
  sm: '600px',
  md: '767px',
  lg: '1000px',
  xl: '1200px',
};

const spacing = {
  xs: '5px',
  sm: '10px',
  md: '15px',
  lg: '30px',
  xl: '60px',
  '2xl': '120px',
};

const pristineConfig = {
  screens,
  bootstrapBreakpoints: {
    min: 0,
    ...screens,
  },
  bootstrapMaxWidths: screens,
  gutters: {
    base: '15px',
  },
  colors: {
    primary: '#008f4f',
  },
  spacing: {
    ...spacing,
  },
  backgroundColor: {},
  backgroundPosition: {},
  backgroundSize: {},
  borderColor: {},
  borderRadius: {},
  borderWidth: {},
  boxShadow: {},
  container: {},
  cursor: {},
  fill: {},
  flex: {},
  flexGrow: {},
  flexShrink: {},
  fontFamily: {
    primary: [
      'Raleway',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
    primary: [
      'Roboto',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
  },
  fontSize: {},
  fontWeight: {},
  height: {},
  inset: {},
  letterSpacing: {},
  lineHeight: {},
  listStyleType: {},
  margin: {},
  maxHeight: {},
  maxWidth: {},
  minHeight: {},
  minWidth: {},
  objectPosition: {},
  opacity: {},
  order: {},
  padding: {
    ...spacing,
  },
  stroke: {},
  textColor: {},
  width: {},
  zIndex: {},
};

module.exports = pristineConfig;
